import { RenderElementProps } from "slate-react";
import CommentCounter from "./CommentCounter";

export default function Text(props: RenderElementProps) {
  return (
    <div
      style={{
        minHeight: "14px",
      }}
      {...props.attributes}
    >
      {props.children}
    </div>
  );
}

export function RenderText(
  props: RenderElementProps & {
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    componentKey: string;
  }
) {
  return (
    <div
      style={{
        minHeight: "14px",
        position: "relative",
      }}
      onClick={(e) => props.onClick && props.onClick(e)}
    >
      <CommentCounter componentKey={props.componentKey}></CommentCounter>
      {props.children}
    </div>
  );
}
