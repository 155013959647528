import { useEffect, useState } from "react";
import { Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { useIsMobile } from "../../../hooks/isMobile.hook";
import { Color } from "../../../theme/theme";
import {
  ComponentType,
  ImagePropsType,
  StoredEditorComponent,
} from "../../../types/graphql";
import GenericButton from "../../Button";
import ImageUpload from "../../ImageUpload/ImageUpload";
import GenericInput from "../../Input";
import Modal from "../../Modal";
import { getProps, setProps } from "../editor.utilities";
import CommentCounter from "./CommentCounter";

function getWidth(imagePropType: ImagePropsType) {
  switch (imagePropType) {
    case ImagePropsType.Center:
      return ["300px", "100%"] as const;
    case ImagePropsType.Left:
      return ["300px", "300px"] as const;
    case ImagePropsType.Full:
      return ["100%", "100%"] as const;
    default:
      return [null, null] as const;
  }
}

export default function Image({
  element,
  attributes,
  children,
}: {
  element: StoredEditorComponent;
  attributes: RenderElementProps["attributes"];
  children: any;
}) {
  const props = getProps(ComponentType.Image, element) || {};
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const [loaded, setLoaded] = useState(false);
  const [width, parentWidth] = getWidth(props?.position);

  useEffect(() => {
    setLoaded(false);
  }, [props.url]);

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={{
        width: parentWidth,
        display: "flex",
        justifyContent: "center",
        paddingTop: "12px",
        paddingBottom: "12px",
      }}
    >
      <div
        style={{
          width,
          minWidth: "300px",
          minHeight: "300px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "100%",
            ...(selected ? { filter: "blur(12px)" } : {}),
          }}
          src={props.url}
          onLoad={() => setLoaded(true)}
        />
        {!loaded && (
          <div
            style={{
              border: "solid",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              height: "100%",
              width: "100%",
              top: "0px",
              color: Color.white,
            }}
          >
            {!selected && <>Image placeholder</>}
          </div>
        )}
        {selected && (
          <>
            <div
              style={{
                backgroundColor: `${Color.dark}50`,
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0px",
              }}
            ></div>
            <div
              style={{
                padding: "12px",
                color: Color.white,
                height: "300px",
                width: "300px",
                position: "absolute",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                top: "0px",
              }}
            >
              <ImageUpload
                urlChange={(url) => {
                  Transforms.setNodes(
                    editor,
                    setProps(ComponentType.Image, { ...props, url })
                  );
                }}
              ></ImageUpload>
              <GenericInput
                label="Source: (url)"
                value={props.url}
                onChange={(value) => {
                  Transforms.setNodes(
                    editor,
                    setProps(ComponentType.Image, {
                      ...props,
                      url: value.target.value,
                    })
                  );
                }}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "24px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GenericButton
                  selected={props.position === ImagePropsType.Left}
                  onClick={() => {
                    Transforms.setNodes(
                      editor,
                      setProps(ComponentType.Image, {
                        ...props,
                        position: ImagePropsType.Left,
                      })
                    );
                  }}
                >
                  small
                </GenericButton>
                <GenericButton
                  selected={props.position === ImagePropsType.Center}
                  onClick={() => {
                    Transforms.setNodes(
                      editor,
                      setProps(ComponentType.Image, {
                        ...props,
                        position: ImagePropsType.Center,
                      })
                    );
                  }}
                >
                  center
                </GenericButton>
                <GenericButton
                  selected={props.position === ImagePropsType.Full}
                  onClick={() => {
                    Transforms.setNodes(
                      editor,
                      setProps(ComponentType.Image, {
                        ...props,
                        position: ImagePropsType.Full,
                      })
                    );
                  }}
                >
                  full width
                </GenericButton>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: "24px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton
                  onClick={() => Transforms.removeNodes(editor, { at: path })}
                >
                  delete
                </GenericButton>
                <GenericButton onClick={() => Transforms.deselect(editor)}>
                  save
                </GenericButton>
              </div>
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
}

export function RenderImage({
  onClick,
  element,
  componentKey,
}: {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  element: StoredEditorComponent;
  componentKey: string;
}) {
  const props = getProps(ComponentType.Image, element) || {};
  const [isModal, setIsModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isMobile = useIsMobile();

  function onClickStrategy(e) {
    !isMobile && setIsModal(true);
    isMobile && setIsHover(!isHover);
    onClick(e);
  }
  const [width, parentWidth] = getWidth(props.position);

  return (
    <>
      <div
        style={{
          width: parentWidth,
          display: "flex",
          justifyContent: "center",
          paddingTop: "12px",
          paddingBottom: "12px",
          position: "relative",
        }}
        onClick={(e) => onClickStrategy(e)}
      >
        <CommentCounter componentKey={componentKey}></CommentCounter>
        <div
          style={{
            width,
            minWidth: "300px",
            minHeight: "300px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {isMobile && isHover && (
            <div
              style={{
                backgroundColor: `${Color.white}69`,
                width: "100%",
                height: "100%",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GenericButton
                onClick={() => {
                  setIsModal(true);
                }}
              >
                enhance
              </GenericButton>
            </div>
          )}
          <img
            style={{
              width: "100%",
            }}
            src={props.url}
          />
        </div>
      </div>
      {isModal && (
        <Modal onConfirm={(b) => setIsModal(b)}>
          <img
            style={{
              width: "100%",
              height: "75vh",
              objectFit: "contain",
            }}
            src={props.url}
          />
        </Modal>
      )}
    </>
  );
}
