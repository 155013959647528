import { RenderElementProps } from "slate-react";
import { ComponentType } from "../../types/graphql";
import Header, { RenderHeader } from "./components/Header";
import Image, { RenderImage } from "./components/Image";
import TextComponent, { RenderText } from "./components/Text";

const TypeToElement = {
  [ComponentType.Text]: TextComponent,
  [ComponentType.Header]: Header,
  [ComponentType.Image]: Image,
} as const;

const TypeToRender = {
  [ComponentType.Image]: RenderImage,
  [ComponentType.Text]: RenderText,
  [ComponentType.Header]: RenderHeader,
};

export function RenderEditor(props: RenderElementProps) {
  const Element = TypeToElement[props.element.type];
  return <Element {...props} />;
}

export function RenderElement(
  props: RenderElementProps & {
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    componentKey: string;
  }
) {
  const Element =
    TypeToRender[props.element.type] || TypeToElement[props.element.type];
  return <Element {...props} />;
}
