import { ReactNode } from "react";
import { useIsMobile } from "../hooks/isMobile.hook";
import { Color } from "../theme/theme";
import GenericButton from "./Button";
export default function Modal({
  children,
  onConfirm,
  primaryButtonText,
}: {
  children: ReactNode;
  onConfirm: (e: boolean) => void;
  primaryButtonText?: string;
}): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: `${Color.dark}88`,
        top: 0,
        left: 0,
        zIndex: 1,
      }}
      onMouseDown={() => onConfirm(false)}
    >
      <div
        style={{
          border: "solid",
          borderColor: Color.dark,
          width: "fit-content",
          padding: isMobile ? "12px" : "48px",
          backgroundColor: Color.white,
          cursor: "pointer",
        }}
      >
        {children}
        {primaryButtonText && (
          <div style={{ paddingTop: "24px" }}>
            <GenericButton onClick={() => onConfirm(true)}>
              {primaryButtonText}
            </GenericButton>
          </div>
        )}
      </div>
    </div>
  );
}
