import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { Comment, QueryCommentsArgs } from "../../../types/graphql";

const QUERY = gql`
  query Comments($input: CommentsFilter!) {
    comments(input: $input) {
      _id
      author {
        _id
        username
        image
      }
    }
  }
`;

export default function CommentCounter({
  componentKey,
}: {
  componentKey: string;
}) {
  const [loadData, { data: { comments } = { comments: [] } }] = useLazyQuery<
    {
      comments: Comment[];
    },
    QueryCommentsArgs
  >(QUERY, {
    variables: {
      input: { componentKey: componentKey },
    },
  });

  useEffect(() => {
    loadData();
  }, [componentKey]);

  return comments?.length ? (
    <div
      style={{
        position: "absolute",
        right: "0px",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        background: "white",
        padding: "4px",
        fontSize: "12px",
        gap: "6px",
        borderRadius: "12px",
        cursor: "pointer",
      }}
    >
      {comments.slice(0, 3).map((comment) => (
        <img
          key={comment._id}
          style={{
            borderRadius: "50%",
            height: "16px",
            width: "16px",
            objectFit: "cover",
          }}
          src={comment.author.image}
        />
      ))}
      {comments.length > 3 && <div>+{comments.length - 3}</div>}
    </div>
  ) : null;
}
