import { RenderElementProps } from "slate-react";
import CommentCounter from "./CommentCounter";

export default function Header(props: RenderElementProps) {
  return <h1 {...props.attributes}>{props.children}</h1>;
}

export function RenderHeader(
  props: RenderElementProps & {
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    componentKey: string;
  }
) {
  return (
    <h1 style={{ position: "relative" }}>
      <CommentCounter componentKey={props.componentKey}></CommentCounter>
      {props.children}
    </h1>
  );
}
