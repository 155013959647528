import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Article = {
  __typename?: 'Article';
  _id: Scalars['String'];
  author?: Maybe<User>;
  content: Array<StoredEditorComponent>;
  isDeleted: Scalars['Boolean'];
  publish?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Date']>;
  shortText?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArticleFilter = {
  articleId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type ArticleInput = {
  _id?: InputMaybe<Scalars['String']>;
  articleId?: InputMaybe<Scalars['String']>;
  content: Array<StoredEditorComponentInput>;
  publish?: InputMaybe<Scalars['Boolean']>;
};

export type Comment = {
  __typename?: 'Comment';
  _id?: Maybe<Scalars['String']>;
  articleId?: Maybe<Article>;
  author?: Maybe<User>;
  componentKey?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  published?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CommentInput = {
  articleId: Scalars['String'];
  componentKey: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
};

export type CommentsFilter = {
  componentKey: Scalars['String'];
};

export type ComponentProps = {
  __typename?: 'ComponentProps';
  image?: Maybe<ImageProps>;
};

export type ComponentPropsInput = {
  image?: InputMaybe<ImagePropsInput>;
};

export enum ComponentType {
  CodeBlock = 'CODE_BLOCK',
  Embed = 'EMBED',
  Header = 'HEADER',
  Image = 'IMAGE',
  Text = 'TEXT'
}

export type ImageProps = {
  __typename?: 'ImageProps';
  position?: Maybe<ImagePropsType>;
  url?: Maybe<Scalars['String']>;
};

export type ImagePropsInput = {
  position?: InputMaybe<ImagePropsType>;
  url?: InputMaybe<Scalars['String']>;
};

export enum ImagePropsType {
  Center = 'CENTER',
  Full = 'FULL',
  Left = 'LEFT'
}

export type Mutation = {
  __typename?: 'Mutation';
  updateArticle: Article;
  updateComment: Comment;
  updateUser: User;
};


export type MutationUpdateArticleArgs = {
  input: ArticleInput;
};


export type MutationUpdateCommentArgs = {
  input?: InputMaybe<CommentInput>;
};


export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserUpdate>;
};

export type Query = {
  __typename?: 'Query';
  article?: Maybe<Article>;
  articles: Array<Article>;
  comment: Comment;
  comments: Array<Comment>;
  me: User;
  user: User;
};


export type QueryArticleArgs = {
  input?: InputMaybe<ArticleFilter>;
};


export type QueryCommentArgs = {
  commentId: Scalars['String'];
};


export type QueryCommentsArgs = {
  input?: InputMaybe<CommentsFilter>;
};


export type QueryUserArgs = {
  input: UserInput;
};

export type StoredEditorComponent = {
  __typename?: 'StoredEditorComponent';
  children: Array<TextChildren>;
  key: Scalars['String'];
  props?: Maybe<ComponentProps>;
  type: ComponentType;
};

export type StoredEditorComponentInput = {
  children: Array<TextChildrenInput>;
  key: Scalars['String'];
  props?: InputMaybe<ComponentPropsInput>;
  type: ComponentType;
};

export type TextChildren = {
  __typename?: 'TextChildren';
  text: Scalars['String'];
};

export type TextChildrenInput = {
  text: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  articleOfTheWeek?: Maybe<Article>;
  articles: Array<Article>;
  email: Scalars['String'];
  followers: Array<User>;
  following: Array<User>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  peopleToFollow: Array<User>;
  username: Scalars['String'];
};

export type UserInput = {
  username: Scalars['String'];
};

export type UserUpdate = {
  following?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image?: InputMaybe<Scalars['String']>;
};
