import isUrl from "is-url";
import {
  ComponentProps,
  ComponentType,
  StoredEditorComponent,
} from "../../types/graphql";

import { ComponentTypeToProps } from "./editor.constants";

export function getProps<T extends ComponentType>(
  componentType: T,
  storedEditorComponent: StoredEditorComponent
): ComponentProps[typeof ComponentTypeToProps[T]] {
  return storedEditorComponent?.props?.[ComponentTypeToProps[componentType]];
}

export function setProps<
  T extends ComponentType,
  R extends StoredEditorComponent["props"][typeof ComponentTypeToProps[T]]
>(componentType: T, value: R) {
  return {
    props: {
      [ComponentTypeToProps[componentType]]: value,
    },
  };
}

export function getText(component: StoredEditorComponent): string {
  return component.children.map((o) => o.text).join("");
}

export function isImageUrl(url: string): boolean {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return ["png", "jpeg"].includes(ext);
}
