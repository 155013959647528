import { ComponentType } from "../../types/graphql";

export const ComponentTypeList = [
  //ComponentType.CodeBlock,
  ComponentType.Image,
  ComponentType.Header,
  ComponentType.Text,
  //ComponentType.Embed,
] as const;

export const ComponentTypeToString = {
  [ComponentType.CodeBlock]: "Code",
  [ComponentType.Image]: "Image",
  [ComponentType.Header]: "Header",
  [ComponentType.Text]: "Text",
  [ComponentType.Embed]: "Embed",
} as const;

export const ComponentTypeToPath = {
  [ComponentType.CodeBlock]: "CodeBlock",
  [ComponentType.Image]: "Image",
  [ComponentType.Header]: "Header",
  [ComponentType.Text]: "Text",
  [ComponentType.Embed]: "Embed",
};

export const ComponentTypeToProps = {
  [ComponentType.CodeBlock]: "image",
  [ComponentType.Image]: "image",
  [ComponentType.Header]: "image", //todo other types
  [ComponentType.Text]: "image",
  [ComponentType.Embed]: "image",
} as const;

export const ComponentTypeIsText = {
  [ComponentType.CodeBlock]: true,
  [ComponentType.Header]: true,
  [ComponentType.Text]: true,
};

export const ComponentTypeIsBlock = {
  [ComponentType.Image]: true,
  [ComponentType.CodeBlock]: true,
  [ComponentType.Embed]: true,
};
