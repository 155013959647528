import { ChangeEvent } from "react";
import { Color } from "../theme/theme";
import GenericButton from "./Button";

export default function GenericInput({
  value,
  onChange,
  label,
  placeholder,
  onClick,
  buttonText,
}: {
  value: string;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  withIcon?: boolean;
  onClick?: () => void;
  buttonText?: string;
}) {
  return (
    <div style={{ width: "100%" }}>
      {label && <p style={{ margin: "0px", paddingBottom: "12px" }}>{label}</p>}
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <input
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onClick();
            }
          }}
          placeholder={placeholder}
          style={{
            border: "solid",
            padding: "8px",
            borderWidth: "1px",
            borderColor: Color.dark,
            width: "100%",
            fontSize: "16px",
          }}
        />
        {buttonText && (
          <GenericButton onClick={() => onClick()}>{buttonText}</GenericButton>
        )}
      </div>
    </div>
  );
}
